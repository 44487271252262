<template>
  <div class="footer">
    <div class="footer__left">
      <div class="footer__left--logo">
        <img src="../../assets/images/logo@2x.webp" width="auto" height="auto" alt="Logo" />
        <p>Glocal</p>
      </div>
    </div>
    <div class="footer__right">
      <div class="footer__right--info">
        <div class="info-desktop">
          Contact Us:
          <a>info@glocal.com</a>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.footer {
  background-color: $color-black;
  padding: 4rem 7.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #FFFFFF3D;
  @include respond(phone-large) {
    padding: 4rem 4rem;
  }
  @include respond(phone-x-small) {
    margin-top: 4rem;
    padding: 4rem 4rem;
  }
  &__left {
    &--logo {
      display: flex;
      align-items: center;
      img {
        width: 3rem;
        height: auto;
        margin-right: 1rem;
      }
      p {
        font-size: 2.4rem;
        color: $color-white;
        margin-bottom: 0;
        font-family: $font-primary-bold;
        line-height: normal;
      }
    }
  }
  &__right {
    &--info {
      .info-desktop {
        margin: 0;
        font-size: 1.8rem;
        color: $color-white;
        font-family: $font-primary-medium;
        display: flex;
        align-items: center;
        a {
          color: $color-white !important;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
</style>
