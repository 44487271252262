<template>
  <div class="landing-page-container">
    <hero-section></hero-section>
    <strategic-action-section></strategic-action-section>
    <about-us-section></about-us-section>
    <expert-professional-section></expert-professional-section>
    <landing-page-footer></landing-page-footer>
  </div>
</template>

<script>
import HeroSection from "../components/LandingPageComponents/HeroSection.vue";
import StrategicActionSection from "../components/LandingPageComponents/StrategicActionSection.vue";
import ExpertProfessionalSection from "../components/LandingPageComponents/ExpertProfessionalSection.vue";
import LandingPageFooter from "../components/layout/LandingPageFooter.vue";
import AboutUsSection from "../components/LandingPageComponents/AboutUsSection.vue"

export default {
  components: {
    HeroSection,
    StrategicActionSection,
    ExpertProfessionalSection,
    LandingPageFooter,
    AboutUsSection
  },
};
</script>

<style lang="scss">
.landing-page-container {
  background-color: $color-black;
  overflow: hidden;
}
</style>
