<template>
  <div class="expert-professional">
    <!-- <img :src="image" alt="expert professional icons" class="expert-professional__icon" height="100" /> -->
    <h1 class="expert-professional__title">{{ name }}</h1>
    <h2 class="expert-professional__description">
      {{ description }}
    </h2>
    <h3 class="expert-professional__company">
      {{ company }}
    </h3>
  </div>
</template>

<script>
export default {
  props: {
    // image: String,
    description: String,
    company: String,
    name: String
  },
};
</script>

<style lang="scss">
.expert-professional {
  padding: 2.5rem 2.8rem;
  max-width: 39.4rem;
  border: 1px solid #c5c6cc;
  border-radius: 2rem;
  background-image: linear-gradient(140deg, #fff 50%, #ffffffa8 100%);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 2rem;
  &__icon {
    width: 12rem;
    height: auto;
    margin: 0 auto;
  }
  &__title {
    font-size: 2.6rem;
    line-height: 3.2rem;
    font-family: $font-primary-bold;
    color: $color-black;
    height: 6.7rem;
    @include respond(phone-x-small) {
      font-size: 2.8rem;
      line-height: 3.3rem;
    }
  }
  &__description {
    margin: 2rem 0;
    font-size: 1.8rem;
    font-family: $font-primary-regular;
    color: $color-black;
    line-height: 2.6rem;
    opacity: 1;
    text-align: left;
    @include respond(tab-port) {
      font-size: 1.6rem;
      line-height: 2rem;
    }
    @include respond(phone-x-small) {
      font-size: 1.8rem;
      line-height: 2.6rem;
    }
  }
  &__company {
    margin: 0;
    font-size: 1.8rem;
    font-family: $font-primary;
    color: #8d8d8d;
    line-height: 2.6rem;
    font-style: italic;
    text-align: left;
    margin-top: auto;
    @include respond(tab-port) {
      font-size: 1.6rem;
      line-height: 2rem;
    }
    @include respond(phone-x-small) {
      font-size: 1.8rem;
      line-height: 2.6rem;
    }
  }
}
</style>
