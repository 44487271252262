<template>
  <a-modal class="activate-account">
    <div class="logo-box">
      <img src="../../assets/images/Amblem2@2x.webp" alt="Glocal Logo" />
    </div>
    <div class="content">
      <h1 class="content__heading">
        Your account is deactivated
      </h1>
      <p class="content__para">
        Activate your account
      </p>
      <form class="content__login-form" @submit.prevent="activeAccoount">
        <div class="form-fields">
          <input
            type="email"
            class="form-input"
            required
            placeholder="Email Address"
            v-model.trim="email"
          />
          <p v-if="error" class="error">
            {{ error }}
          </p>
          <a-button
            class="form-btn btn-primary form-btn"
            htmlType="submit"
            :loading="loading"
          >
            Activate
          </a-button>
        </div>
      </form>
    </div>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      error: "",
      loading: false,
    };
  },
  emits: ["show-message-modal"],
  methods: {
    async activeAccoount() {
      try {
        this.loading = true;
        this.error = "";
        const payload = {
          email: this.email,
        };

        await this.$store.dispatch("auth/accountActivate", payload).then(() => {
          this.$emit("show-message-modal");
        });
      } catch (err) {
        this.error = err;
        console.log(err);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.activate-account {
  width: 45rem !important;

  .ant-modal-content {
    padding: 5rem 4rem;
    border-radius: 0;
    .ant-modal-body {
      .logo-box {
        img {
          width: 3.2rem;
          display: block;
          margin: auto;
        }
      }

      .content {
        margin-top: 2rem;
        &__heading {
          font-size: 2rem;
          font-family: $font-primary-bold;
          text-align: center;
          margin-bottom: 0rem;
          line-height: 2.6rem;
        }
        &__para {
          font-size: 1.6rem;
          font-family: $font-primary;
          text-align: center;
          opacity: 0.6;
          margin-bottom: 2rem;
        }
        &__login-form {
          .form-input {
            width: 100%;
            height: 4rem;
            border: 1px solid #70707033;
            background-color: #f6f8ff;
            padding: 0 2rem;
            outline: none;
            margin-bottom: 0.5rem;
          }
          .error {
            font-size: 1.4rem;
            color: red;
            line-height: normal;
            margin-bottom: 0;
          }
          .form-btn {
            color: $color-white;
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            background-color: $color-primary;
            border: none;
            margin-left: auto;
            display: block;
            width: 100%;
            height: 4.2rem;
            margin-top: 1.5rem;
            cursor: pointer;
            &:hover,
            &:active,
            &:focus {
              background-color: $color-primary;
              color: $color-white;
            }
          }
        }
      }
    }
  }
}
</style>
