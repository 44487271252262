<template>
  <section class="hero-section">
    <div class="hero-section__bg-video">
      <video ref="video"  preload="metadata" playsinline muted autoplay loop type="video/mp4">
        <source
          src="@/assets/videos/holographic-planet-globe-of-earth-from-space.mp4"
        />
        <track kind="captions" srclang="en" label="holographic_planet_globe_of_earth_from_space">
      </video>
    </div>
    <landing-page-header></landing-page-header>
    <div class="hero-section__content">
      <h2 class="hero-section__content--title">
        NEWS INTELLIGENCES
        <hr />
        <p>POWERED BY PROFESSIONALS & AI</p>
      </h2>
      <button class="hero-section__content--btn login-btn-hover color" @click="showAccessModal('login')">Access Glocal</button>
    </div>
    <a class="hero-section__scroll-btn" href="#for-active-navbar">
      <p>Scroll down for more</p>
      <img src="@/assets/icons/scroll-down-arrow.svg" alt="" />
    </a>
  </section>
  <subscription-modal
    :visible="visibleAccessModal"
    :type="modalType"
    @closeAccessModal="closeAccessModal"
  ></subscription-modal>
  <student-subscription
    :visible="visibleStudentSubscriptions"
    @closeAccessModal="closeAccessModal">
  </student-subscription>
</template>

<script>
import LandingPageHeader from "../layout/LandingPageHeader.vue";
import SubscriptionModal from "../../components/Auth/AccessModal.vue"
import StudentSubscription from "../../components/Auth/StudentSubscriptions.vue"

export default {
  components: {
    LandingPageHeader,
    SubscriptionModal,
    StudentSubscription
  },
  data() {
    return {
      visibleAccessModal: false,
      modalType: "",
      visibleStudentSubscriptions: false
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (this.$route.path === '/student-sign-up' || this.$route.path === '/student-sign-up/' ) {
          this.visibleStudentSubscriptions = true;
        }
      },
    },
  },
mounted() {
  // for loading video faster
  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const video = entry.target;
          video.play(); // Start video playback when it enters the viewport
          observer.unobserve(video); // Stop observing once the video starts playing
        }
      });
    },
    { threshold: 0.1 } // Trigger when 50% of the video is visible
  );

  const video = this.$refs.video;
  observer.observe(video);
},
methods: {
    showAccessModal(val) {
      this.modalType = val,
      this.visibleAccessModal = true;
    },
    closeAccessModal() {
      this.visibleAccessModal = false;
      this.modalType = ''
    },
  }
}

</script>

<style lang="scss" scoped>
.hero-section {
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $color-black;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: bottom;
  position: relative;
  &__scroll-btn {
    background-color: transparent;
    position: absolute;
    bottom: 7rem;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    color: $color-white;
    font-size: 2.2rem;
    opacity: 0.7;
    text-align: center;
    cursor: pointer;
    display: none;
    img {
      width: 3rem;
      margin-top: 1.5rem;
    }
    @include respond(phone-x-small) {
      display: block;
    }
    &:hover,
    &:focus {
      color: $color-white;
    }
  }
  &__content {
    padding: 0 7.2rem;
    position: relative;
    margin: 3rem auto 0;
    @include respond(tab-port) {
      padding: 0 4rem;
    }
    &--title {
      font-size: 8rem;
      color: $color-white;
      font-family: $font-primary-bold-2;
      line-height: 9.4rem;
      text-align: center;
      p {
        font-family: $font-primary-regular;
        color: #bababa;
        margin-bottom: 0; 
      }
      hr {
        height: 1px;
        background-color: transparent;
        border: none;
        margin: 0.5rem 0;
      }
      @include respond(laptop-large) {
        font-size: 6.8rem;
        line-height: 8rem;
      }
      @include respond(tab-land) {
        font-size: 6.8rem;
        line-height: 8rem;
      }
      @media (max-width: 1030px) {
        font-size: 5rem;
        line-height: 6rem;
      }
      @include respond(phone-x-small) {
        font-size: 4.2rem;
        line-height: 5rem;
        p {
          font-size: 3.2rem;
          line-height: 4rem;
        }
      }
      @media (max-width: 372px) {
        font-size: 4rem;
        line-height: 4.5rem;
        p {
          font-size: 2.8rem;
          line-height: 3.2rem;
        }
      }
    }
    &--btn {
      background-color: #688bff;
      border: none;
      padding: 1.5rem 5rem;
      font-size: 1.8rem;
      font-family: $font-primary-medium;
      color: $color-white;
      line-height: normal;
      cursor: pointer;
      margin: 2rem auto 0;
      display: block;
      border-radius: 7px !important;
    }
    .login-btn-hover {
      background-size: 300% 100%;
      moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }

    .login-btn-hover:hover {
      background-position: 100% 0;
      moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }

    .login-btn-hover.color {
      background-image: linear-gradient(to right,
          #3660ec,
          $color-primary,
          $color-primary,
          #688bff,
        );
    }
  }
  &__bg-video {
    position: absolute;
    animation: VideMoveIn 2s ease-in;
    width: 100vw;
    overflow: hidden;

    video {
      object-fit: cover;
      width: 100vw;
      height: 97vh;
      top: 0;
      left: 0;
    }

    &:before {
      content: "";
      position: absolute;
      background: rgba(0, 0, 0, 0.622);
      border-radius: 5px;
      top: 0;
      right: 0;
      bottom: 6px;
      left: 0;
    }
  }
}
</style>
