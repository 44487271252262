<template>
  <a-modal width="36.7rem" @cancel="$emit('close-modal')">
    <div class="logo-box">
      <img
        class="logo"
        src="../../assets/images/Amblem2@2x.webp"
        alt="Glocal Logo"
      />
    </div>
    <div class="content-waitlist">
      <h1 class="content-waitlist__heading">
        Get early access when we launch this year!
      </h1>
      <!-- <p class="content-waitlist__para">
        Be one of the first to create a profile and claim a premium username
      </p> -->
      <a-form
        :layout="'horizontal'"
        class="join-waitlist-form"
        @submit.prevent="joinWaitlist"
      >
        <div class="form-fields">
          <div class="form-group">
            <label for="first-name">First Name</label>
            <a-input
              type="text"
              id="first-name"
              v-model:value="firstName"
              placeholder="Enter First Name"
              @change="error = ''"
            />
            <p v-if="firstNameError" class="error">{{ firstNameError }}</p>
          </div>
          <div class="form-group">
            <label for="last-name">Last Name</label>
            <a-input
              type="text"
              id="last-name"
              v-model:value="lastName"
              placeholder="Enter Last Name"
              @change="error = ''"
            />
            <p v-if="lastNameError" class="error">{{ lastNameError }}</p>
          </div>
          <div class="form-group">
            <label for="email">Email Address</label>
            <a-input
              type="email"
              id="email"
              placeholder="Enter Email"
              v-model:value="email"
              @change="error = ''"
            />
            <p v-if="emailError" class="error">{{ emailError }}</p>
          </div>
          <div></div>
        </div>
        <a-button
          htmlType="submit"
          :loading="loading"
          class="btn-join-waitlist btn-primary"
        >
          Submit
        </a-button>
        <p class="early-access-definition">
          Signing up early helps us better serve you with the News Intelligence™
          you need, and if you desire, update you on new products and services
          developed to serve your requirements.
        </p>
      </a-form>
    </div>
    <a-modal
      width="45rem"
      :zIndex="200000000000"
      :visible="successMessageVisible"
      :message="message"
      @cancel="successMessageVisible = false"
      :footer="null"
    >
      <div class="logo-box">
        <img
          class="logo"
          src="../../assets/images/Amblem2@2x.webp"
          alt="Glocal Logo"
        />
      </div>
      <div class="modal-content">
        <p class="publish-text">
          Hi, {{ message.name }}<br />
          <br />
          Welcome to Glocal’s News Intelligence™ community! We look forward to
          providing you with the best intelligence environment. <br />
          Glocal is continuously improved through member feedback. If you have
          any questions and/or would like to provide feedback, you can contact
          us at:
          <a class="mailto-link" href="mailto:info@glocal.com"
            >info@glocal.com</a
          >
          <br />
          <span class="invite-text">Share this link with your friends to invite them to Glocal
          <br />
          <a href="#" class="invite-link">glocal.com/invite/{{referralId}}</a> 
          </span>
          <br />Best regards, Glocal team
        </p>
      </div>
    </a-modal>
    <message-toaster 
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
    >
    </message-toaster>
  </a-modal>
</template>
<script>
import ApiService from "../../services/api.service.js";
import StorageService from "../../services/storage.service";
import MessageToaster from '../BaseComponents/MessageToaster.vue';

export default {
  components: {
    MessageToaster
  },
  data() {
    return {
      email: "",
      firstName: "",
      lastName: "",
      // error: "",
      showMessageModal: false,
      message: {},
      loading: false,
      successMessageVisible: false,
      firstNameError: "",
      emailError: "",
      lastNameError: "",
      referralId: null,
    };
  },
  methods: {
    async joinWaitlist() {
      this.emailError = "";
      this.firstNameError = "";
      this.lastNameError = "";

      if (this.firstName === "") {
        this.firstNameError = "First Name field is required";
        return;
      } else if (this.lastName === "") {
        this.lastNameError = "Last Name field is required";
        return;
      } else if (this.email === "") {
        this.emailError = "email field is required";
        return;
      }
      ApiService.removeHeader();
      this.loading = true;
      this.referralId = StorageService.getData("referral_id") ?  StorageService.getData("referral_id") : null;
      const requestObj = {
        email: this.email,
        firstname: this.firstName,
        lastname: this.lastName,
      }
      if(StorageService.getData("referral_id")){
        requestObj.referral_id = this.referralId
      }
      await ApiService.post("user/waitlist/", requestObj)
        .then((response) => {
          if (response.status === 201) {
            this.message.name = this.firstName + " " + this.lastName;
            this.successMessageVisible = true;
            this.referralId = response.data.referral_id;
            StorageService.removeData("referral_id") ;
          } else if (response.status === 200) {
            this.message = {
              title: "This email already exists",
              desc: `Please contact info@glocal.com if you’re experiencing registration issues.
                    Thank you`,
              type: "failure",
            };
            this.showMessageModal = true;
          }
        })
        .catch((err) => {
          if (err.response.data.email) {
            this.message = {
              title: "This email already exists",
              desc: "Please contact info@glocal.com if you’re experiencing registration issues.</br>Thank you",
              type: "failure",
            };
          } else {
            this.message = {
              title: "Something went wrong",
              desc: "Please contact info@glocal.com if you’re experiencing registration issues.</br>Thank you",
              type: "failure",
            };
          }
          this.showMessageModal = true;
        });
      this.loading = false;
      this.email = "";
      this.firstName = "";
      this.lastName = "";
      this.$emit("close-modal");
    },
  },
};
</script>
<style lang="scss" scoped>
.content-waitlist {
  padding: 0 4.8rem 4rem;
  &__heading {
    text-align: center;
    font-size: 2rem;
    font-family: $font-secondary-bold;
    padding-top: 1.9rem;
  }
  &__para {
    text-align: center;
    margin-bottom: 2.4rem;
    font-size: 1.2rem;
  }
  .join-waitlist-form {
    .form-fields {
      padding: 0;
      margin-bottom: 1.5rem;
      .form-group {
        margin-bottom: 1rem;
        label {
          display: inline-block;
          margin: 0.4rem 0;
          font-size: 1.4rem;
        }
        .ant-input {
          font-size: 1.6rem;
          &::-webkit-input-placeholder {
            font-size: 1.2rem;
          }
          &::-moz-placeholder {
            font-size: 1.2rem;
          }
        }
        .error {
          font-size: 1.3rem;
          margin-bottom: 0;
        }
      }
    }
    .btn-join-waitlist {
      width: 100%;
      font-family: $font-secondary-bold;
      font-size: 1.2rem;
      height: 4rem;
    }
  }
}
.publish-text {
  .mailto-link {
    color: $color-primary;
  }
  .invite-text {
    margin: 1rem 0;
    display: inline-block;
    .invite-link {
      color: $color-primary;
    }
  }
}
.early-access-definition{
  margin-top: 2rem;
  text-align: justify;
  font-size: 1.2rem;
}
</style>
