<template>
  <a-modal @cancel="closeModal" class="message-modal" :footer="null">
    <div class="message-modal-container">
      <div class="message-modal-container__tick-circle" :class="dynamicClass">
        <i
          v-if="message.type == 'success'"
          class="icon icon-tick-mark_circle"
        ></i>
        <i v-else class="icon icon-cross"></i>
      </div>
      <h2 data-cy="addModalMessage" class="message-modal-container__heading">
        {{ message.title }}
      </h2>
      <p class="message-modal-container__text">
        {{ message.desc }}
      </p>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: ["message"],
  emit: ['closeModal'],
  data() {
    return {
      status: "",
    };
  },
  computed: {
    dynamicClass() {
      return this.message.type;
    },
  },
  methods: {
    closeModal(){
      this.$emit('closeModal')
    }
  }
};
</script>
<style lang="scss">
.message-modal {
  width: 45rem !important;
  .ant-modal-content {
    .ant-modal-close {
      margin: 3.7rem 3.7rem 0 0;

      .ant-modal-close-x {
        padding: 0.5rem;
        width: fit-content;
        height: fit-content;
        line-height: normal;

        .anticon {
          color: $color-black;
        }
      }
    }
    .ant-modal-body {
      .message-modal-container {
        padding: 3.7rem 3.7rem 4rem;
        &__tick-circle {
          width: fit-content;
          display: block;
          margin: 0 auto 1rem;
          .icon {
            font-size: 4rem;
          }
        }
        &__heading {
          font-size: 2.4rem;
          font-family: $font-primary-medium;
          margin-bottom: 2rem;
          text-align: center;
          line-height: 3rem;
          color: $color-black;
        }
        &__text {
          font-size: 1.6rem;
          font-family: $font-primary;
          margin-bottom: 0;
          text-align: center;
          line-height: 2.2rem;
          color: $color-black;
          opacity: 0.7;
        }
        .success {
          .icon {
            color: green;
          }
        }
        .failure {
          .icon {
            color: red;
          }
        }
      }
    }
  }
}
</style>
